import React, { useState, useEffect } from "react";
//import {Link} from "react-router-dom"
//import {makeStyles} from '@material-ui/core/styles'
import Header from "../components/header/Header";
import NavBar from "../components/header/NavBar";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CssBaseline,
} from "@material-ui/core";
import About from "./About";
import Features from "./Features";
import Contact from "./Contact";
import Rewards from "./Rewards";
import playstore from "../assets/images/google-play-badge.svg";
//import appstore from "../assets/images/app-store-badge.svg";
import Footer from "../components/Footer";
//import Gallery from "./Gallery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import VideoCarousel from "./VideoCarousel";
import History from "./History";
import Careers from "./Careers";
import ImageSlider from "./Slider";
import OurMission from "./OurMission";
import Overview from "./Overview";

export default function Home() {
  // const [data , setData]=useState(null);
  const [about, setAbout] = useState([]);
  const [features, setFeatures] = useState([]);
  const [googlePlayUrl, setGooglePlayUrl] = useState("");
  const [history, setHistory] = useState([]);
  const [ourMission, setOurMission] = useState([]);
  const [overview, setOverview] = useState([]);
  const fetchData = () => {
    fetch("./data/content.json")
      .then((res) => {
        //console.log(res, " resC");
        return res.json();
      })
      .then((d) => {
        //setData(d)
        //console.log(d, " resCd");
        setAbout(d.about);
        setFeatures(d.features);
        setHistory(d.history);
        setOurMission(d.ourMission);
        setOverview(d.overview);
        setGooglePlayUrl(d.googlePlayUrl);
      })
      .catch((err) => {
        console.log(err, " error");
      });
  };

  const [appTitle, setAppTitle] = useState("");
  const [header, setHeader] = useState(null);
  const [subHeader, setSubHeader] = useState(null);

  const fetchHeaderData = () => {
    fetch("./data/header.json")
      .then((res) => {
        //console.log(res, " res");
        return res.json();
      })
      .then((d) => {
        setAppTitle(d.appTitle);
        setHeader(d.header);
        //console.log(d.header, " h");
        setSubHeader(d.subHeader);
      })
      .catch((err) => {
        console.log(err, " error");
      });
  };
  const [videoUrls, setVideoUrls] = useState([]);
  const fetchVideoData = () => {
    fetch("./data/video.json")
      .then((res) => {
        //console.log(res, " res");
        return res.json();
      })
      .then((d) => {
        setVideoUrls(d.videos);
      })
      .catch((err) => {
        console.log(err, " error");
      });
  };
  const [imagesGrids, setImagesGrids] = useState([]);
  const fetchImageData = () => {
    fetch("./data/image.json")
      .then((res) => {
        //console.log(res, " resI");
        return res.json();
      })
      .then((d) => {
        //console.log(d, " resId");
        setImagesGrids(d.imageGrids);
      })
      .catch((err) => {
        console.log(err, " error");
      });
  };
  useEffect(() => {
    // Fetch Function
    fetchData();
    fetchHeaderData();
    fetchVideoData();
    fetchImageData();
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <NavBar appTitle={appTitle} color={"transparent"} position={"fixed"} />
      <Header header={header} subHeader={subHeader} />
      <Container>
        <br/>
        <br/>
        <About content={about} />
        <br />
        <br />
        <Overview content={overview} />
        <br />
        <br />
        <VideoCarousel videoUrls={videoUrls} />
        <br />
        <br />
        <History content={history} />
        <br />
        <br />
        <OurMission content={ourMission} />
        <br />
        <br />
        <Features featuresList={features} />
        <br />
        <br />
        {/* <VideoPlayer title="Watch Video" width="100%" height="100%" /> */}
        

        <br />
        <br />
        
        {/* <Gallery imageGrids={imagesGrids} /> */}
        <ImageSlider imageGrids={imagesGrids} />
        <br />
        <br />

        <div id="download">
          <Typography
            component="h3"
            variant="h3"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Discover what all the buzz is about!
          </Typography>
          <Typography variant="h5" align="center">
            Our app is available on android mobile device! Download now to get
            started!
          </Typography>
          <br />
          <br />
          <Grid container spacing={9}>
            <Grid item xs={3} md={4}></Grid>
            <Grid item xs={6} md={4}>
              <Card>
                <CardActionArea
                  onClick={() => {
                    window.location.href = googlePlayUrl;
                  }}
                >
                  <CardMedia component="img" image={playstore} />
                  {/* </Link> */}
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={3} md={4}></Grid>
            {/* <Grid item xs={3} md={1}></Grid>
            <Grid item xs={6} md={3}>
              <Card>
                <CardActionArea>
                  <CardMedia component="img" image={appstore} />
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={3} md={2}></Grid> */}
          </Grid>
          <br />
          <br />
        </div>
        <Careers />
        <br />
        <br />
        <Rewards />
        <br />
        <br />
        <Contact />
      </Container>
      <br/>
      <Footer />
    </React.Fragment>
  );
}
