import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormGroup,
  Checkbox,
  makeStyles,
  FormHelperText,
  Collapse,
  IconButton,
  Paper,
  // makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
// import { Link } from "react-router-dom";
import DialogBox from "../components/DialogBox";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import ContactUsSevice from "../services/ContactUsSevice";
import Loader from "../components/Loader";
import ContactUsForm from "./ContactUsForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 0,
  },

  addressBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    //minWidth: "50"
  },
}));

export default function Contact() {
  const classes = useStyles();

  return (
    <div id="contact">
      <Typography variant="h3" align="center">
        Contact Us
      </Typography>
      <ContactUsForm />
      <br />
      <Typography variant="h4" align="Left">
        Address
      </Typography>
      <br/>
      <Grid container>
        <Grid sm={12} md={6} lg={6}>
          <Paper elevation={0} className={classes.addressBox}>
            <Typography variant="h6" gutterBottom>
              Corporate Office
            </Typography>
            <Typography>
              Sarjapur Main Road, Jakkasandra, Koramangala, Bengaluru, 
              Karnataka(India) -560047
            </Typography>
            <Typography variant="body2">Mobile Number: 8541080907</Typography>
          </Paper>
        </Grid>
        <Grid sm={12} md={6} lg={6}>
          <Paper elevation={0} className={classes.addressBox}>
            <Typography variant="h6" gutterBottom>
               Documentation Center 
            </Typography>
            <Typography>
              Ashok Ratna Arcade Palace, Dange Chowk, Pimpri Chinchwad, Pune
              411033
            </Typography>
            <Typography variant="body2">Mobile Number: 8541080907</Typography>
            <br />
            <Typography>
              {" "}
              #Bdg 753, Sector 29, Belapur, Thane, Mumbai-400614
            </Typography>
            <Typography variant="body2">Mobile Number: 8541080907</Typography>
            <br />
            <Typography>Ganesh Complex, Barahat, Banka - 813103</Typography>
            <Typography variant="body2">Mobile Number: 8541080907</Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
