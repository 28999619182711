import { Card, Container, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import VideoPlayer from "../components/VideoPlayer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1100,
    maxHeight: 612,
    justifyItems: "center",
  },
  // videoContainer: {
  //   alignItems="center"
  // justify="center"
  // }
}));

export default function VideoFrame() {
  const classes = useStyles();
  const [videoUrl, setVideoUrl] = useState("");
  const fetchVideoData = () => {
    fetch("../data/video.json")
      .then((res) => {
        //console.log(res, " res");
        return res.json();
      })
      .then((d) => {
        //console.log(d, " res");
        setVideoUrl(d.fixedVideo);
      })
      .catch((err) => {
        //console.log(err, " error");
      });
  };
  useEffect(() => {
    fetchVideoData();
  }, []);
  return (
    <Container>
      <Grid container alignItems="center" justify="space-evenly">
        <Grid item xs={12} md={12} lg={12}>
          <Card className={classes.root}>
            <VideoPlayer
              loop={true}
              controls={true}
              width="100%"
              height="100%"
              isSelected={true}
              url={videoUrl}
              interval={10000}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
