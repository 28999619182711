import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormGroup,
  Checkbox,
  FormHelperText,
  Collapse,
  IconButton,
  // makeStyles,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
// import axios from "axios";
// import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import DialogBox from "../components/DialogBox";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import RewardsService from "../services/RewardsService";
import ImageUploadService from "../services/ImageUploadService";
import Loader from "../components/Loader";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";

export default function Rewards() {
  const [rewardsUserDetails, setRewardsUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    filePath: "",
    city: "",
    zipCode: "",
    privacyAccepted: false,
  });
  const [uploadedImage, setUploadedImage] = useState("");
  const [uploadedImageValue, setUploadedImageValue] = useState("");
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertOpen, setAlertOpen] = useState(true);
  const [errorMsg, setErrorMsg] = useState(
    " Please fill all the required details!"
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [pinError, setPINError] = useState(false);
  const [privacyCheckedError, setPrivacyCheckedError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setRewardsUserDetails({
      ...rewardsUserDetails,
      [name]: value,
    });
  };

  const handleImageChange = (event) => {
    console.log("Puzzz: ", event.target.files[0]);
    setUploadedImage(event.target.files[0]);
    const file = event.target.files[0];
    if (!file || file.size > 5242880 || !file.type.includes("image")) {
      //5248256
      setImageError(true);
      setUploadedImage("");
      event.target.value = null;
    } else {
      setUploadedImage(file);
      setUploadedImageValue(event.target.value);
    }
  };
  const clearContactDetails = () => {
    setRewardsUserDetails({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      filePath: "",
      city: "",
      zipCode: "",
      privacyAccepted: false,
    });
    setUploadedImageValue("");
  };
  const handlePrivacyAccepted = (event) => {
    setRewardsUserDetails({
      ...rewardsUserDetails,
      privacyAccepted: event.target.checked,
    });
  };
  const renderDialogContent = () => {
    if (showPrivacy) {
      console.log("showing privacy policy....");
      return (
        <React.Fragment>
          <PrivacyPolicy />
        </React.Fragment>
      );
    } else if (showTerms) {
      return (
        <React.Fragment>
          <TermsConditions />
        </React.Fragment>
      );
    }
  };
  const renderDialogActions = () => {
    return (
      <Button color="primary" variant="contained" onClick={handleDialogClose}>
        Close
      </Button>
    );
  };
  const handleDialogClose = () => {
    setShowTerms(false);
    setShowPrivacy(false);
  };

  const validateForm = () => {
    let isError = false;
    if (rewardsUserDetails.firstName === "") {
      isError = true;
      setFirstNameError(true);
    }
    if (rewardsUserDetails.mobile === "") {
      isError = true;
      setMobileError(true);
    }
    if (rewardsUserDetails.email === "") {
      isError = true;
      setEmailError(true);
    }
    if (rewardsUserDetails.city === "") {
      isError = true;
      setCityError(true);
    }
    if (rewardsUserDetails.zipCode === "") {
      isError = true;
      setPINError(true);
    }
    if (rewardsUserDetails.privacyAccepted === false) {
      isError = true;
      setPrivacyCheckedError(true);
    }

    if (uploadedImage === "" || uploadedImage === null) {
      isError = true;
      setError(true);
      setImageError(true);
    }

    return isError;
  };

  useEffect(() => {
    if (rewardsUserDetails.filePath !== "" && !error) {
      console.log("filepath: ", rewardsUserDetails.filePath);
      RewardsService.saveRewardsData(rewardsUserDetails)
        .then(() => {
          clearContactDetails();
          console.log("Data saved successfully");
        })
        .catch(() => {
          //console.log("Failed to save data");
          setError(true);
          setErrorMsg("Something went wrong! Please try again.");
        });

      RewardsService.sendMailToUser(rewardsUserDetails)
        .then(() => {
          setSuccess(true);
          console.log("User mail sent successfully");
        })
        .catch(() => {
          console.log("Failed to send user mail");
          setError(true);
          setErrorMsg("Something went wrong! Please try again.");
        });
      RewardsService.sendInternalMail(rewardsUserDetails)
        .then(() => {
          setBtnDisabled(false);
          setShowLoader(false);
          setSuccess(true);
          console.log("Internal mail sent successfully");
        })
        .catch(() => {
          setBtnDisabled(false);
          setShowLoader(false);
        });
    }
  }, [rewardsUserDetails]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    let isError = validateForm();
    setError(isError);
    setAlertOpen(false);
    if (!isError) {
      setBtnDisabled(true);
      setShowLoader(true);
      await handleImageUpload();
    }
  };

  const handleImageUpload = async () => {
    // let isError = false;
    // if (uploadedImage === "" || uploadedImage === null) {
    //   isError = true;
    //   setError(true);
    //   setImageError(true);
    // }
    if (!error) {
      let formData = new FormData();
      formData.append("fnm", "Others");
      formData.append("mdl", 8);
      formData.append("file", uploadedImage);
      formData.append("limg", rewardsUserDetails.firstName +"-"+ rewardsUserDetails.mobile + "-"+ uploadedImage.name);
      console.log("img: ", uploadedImage);
      await ImageUploadService.upload(formData)
        .then((response) => {
          console.log("imgResc ", response.data);
          console.log("imgRescFolder ", response.data.folderName);
          setRewardsUserDetails({
            ...rewardsUserDetails,
            filePath: response.data.folderName,
          });
          console.log("updStc: ", rewardsUserDetails);
        })
        .catch(() => {
          console.log("Failed to upload image");
          setError(true);
          setErrorMsg("Failed to upload Image ! Please try again.");
          setBtnDisabled(false);
          setShowLoader(false);
          clearContactDetails();
        });
    }
  };

  return (
    <div id="rewards">
      <Typography variant="h3" align="center">
        Rewards
      </Typography>
      <br />
      <Typography paragraph>
        Upload the solved puzzle here with your basic details and get enroll in
        the contest.....
      </Typography>
      <a href="http://justconnectunite.com/images/puzzle.jpeg" target="_blank" rel="noreferrer">Click here</a>  <Typography component="span" varient="body2" gutterBottom> to view puzzle.</Typography>
      <br />
      <br/>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="First name"
              value={rewardsUserDetails.firstName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              onFocus={() => {
                setError(false);
                setFirstNameError(false);
              }}
              error={firstNameError}
              //autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Last name"
              value={rewardsUserDetails.lastName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              //autoComplete="family-name"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              value={rewardsUserDetails.email}
              onChange={handleChange}
              fullWidth
              type="email"
              variant="outlined"
              onFocus={() => {
                setError(false);
                setEmailError(false);
              }}
              error={emailError}
              //autoComplete="shipping postal-code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="mobile"
              name="mobile"
              label="Mobile No."
              value={rewardsUserDetails.mobile}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              onFocus={() => {
                setError(false);
                setMobileError(false);
              }}
              error={mobileError}
              //autoComplete="shipping country"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="City"
              value={rewardsUserDetails.city}
              onChange={handleChange}
              fullWidth
              type="text"
              variant="outlined"
              onFocus={() => {
                setError(false);
                setCityError(false);
              }}
              error={cityError}
              //autoComplete="shipping postal-code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zipCode"
              name="zipCode"
              label="PIN Code"
              value={rewardsUserDetails.zipCode}
              onChange={handleChange}
              fullWidth
              type="text"
              variant="outlined"
              onFocus={() => {
                setError(false);
                setPINError(false);
              }}
              error={pinError}
              //autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              accept="*"
              // className={classes.input}
              id="contained-button-file"
              name="icon"
              label="Icon"
              multiple
              type="file"
              value={uploadedImageValue}
              onChange={handleImageChange}
              helperText="Please select solved puzzle(Only .jpg or png of size below 5 MB)"
              required
              on
              onFocus={() => {
                setError(false);
                setImageError(false);
              }}
              error={imageError}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography paragraph>
              By submiting details you are agreeing to our{" "}
              <Button
                color="primary"
                onClick={() => {
                  setShowTerms(true);
                }}
              >
                Terms & Condition
              </Button>
              {/* <a href="#"
                onClick={() => {
                  setShowTerms(true);
                }}
              >
                Terms & Condition
              </a> */}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rewardsUserDetails.privacyAccepted}
                    onChange={handlePrivacyAccepted}
                    onFocus={() => {
                      setError(false);
                      setPrivacyCheckedError(false);
                    }}
                    error={privacyCheckedError}
                    name="privacyChecked"
                    color="primary"
                  />
                }
              />
              <Typography paragraph>
                I agree and accept{" "}
                <Button
                  color="primary"
                  onClick={() => {
                    setShowPrivacy(true);
                  }}
                >
                  Privacy Policy
                </Button>
              </Typography>
            </FormGroup>
          </Grid>
          <DialogBox
            showDialog={showPrivacy || showTerms}
            dialogContent={renderDialogContent()}
            dialogActions={renderDialogActions()}
            onClose={handleDialogClose}
          ></DialogBox>
          <Grid item xs={12}>
            {showLoader && <Loader />}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={btnDisabled}
              //className={classes.submit}
            >
              Submit
            </Button>
            <br />
            <br />
            {error && (
              <Alert variant="outlined" severity="error">
                {errorMsg}
              </Alert>
            )}
            {success && (
              <Collapse in={alertOpen}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Thanks for Participating!
                </Alert>
              </Collapse>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
