import axios from "axios";
import { IMAGE_UPLOAD_ENDPOINT } from "../constants/Constants";

class ImageUploadService {
  upload(formData) {
    console.log("fd: ", formData);
    let folderName,moduleName,category,prevImage;
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
      if(key[0]==="fnm")
        folderName=key[1];
      else if(key[0]==="mdl")
        moduleName=key[1];
      else if(key[0]==="limg")
        prevImage=key[1];
      else if(key[0]==="catg")
        category=key[1];     
    }

    //const url = BACKEND_BASE_URL + IMAGE_UPLOAD_ENDPOINT;
    const url= IMAGE_UPLOAD_ENDPOINT + `?fnm=${folderName}&mdl=${moduleName}&catg=${category}&limg=${prevImage}`;
    return axios.post(
      url,formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}
export default new ImageUploadService();
