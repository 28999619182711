import React from "react";
import { Container, Typography } from "@material-ui/core";

export default function DeliveryPolicy() {
  return (
    <React.Fragment>
      <Container>
        <Typography variant="h3" align="center">
          JCU Shipping and Delivery Policy
        </Typography>

        <br />
        <br />
        <br />
        <Typography paragraph>
          Delivery and Shipping is not applicable for business.
          <a href="https://merchant.razorpay.com/policy/KK0rXXxziIvHqQ/shipping">
            Click here!
          </a>{" "}
        </Typography>
        <Typography paragraph>
          We do not control for delivery and shipping provided by Merchants
          using our platform for some products.
          <br /> So any issue related delivery of those products will be handled
          by concerned merchant.
        </Typography>
        <Typography paragraph>
          For more info please contact our support team:{" "}
          <a href="support@justconnectunite.com">
            support@justconnectunite.com
          </a>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
