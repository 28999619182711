/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect ,useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
//import Particles from "react-particles-js";
import Typed from "react-typed";

import { Box, Typography, Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "white",
  },
  subTitle: {
    color: "rgb(22 20 60)",
    marginBottom: "3rem",
  },
  headerContainer: (props) => ({
    //  position: "absolute",
    //   top: "50%",
    //   left:"50%",
    //   transform: "translate(-50%,-50%)",
    //   marginTop: "10px",
    backgroundImage: `url(${window.location.origin + props.imageName})`,
    //  backgroundImage: `url(${window.location.origin + '/images/banner-bkp.jpg'})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    //width: "100%",
    height: "110vh",
    zIndex: 1,
    display: "flex",
  }),
  typedContainer: {
    position: "absolute",
    top: "70%",
    left: "40%",
    transform: "translate(-50%,-50%)",
    textAlign: "left",
  },

  buttonContainer: {
    position: "absolute",
    top: "80%",
    left: "40%",
    transform: "translate(-50%,-50%)",
    textAlign: "left",
  },
  particlesCanva: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
}));

export default function Header(props) {
  const header = props.header;
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const styleProps = {
    imageName: mobileView ? "/images/banner-mob.gif" : "/images/banner-desktop.gif",
  };
  const classes = useStyles(styleProps);

  return (
    <div className={classes.headerContainer}>
      <Box className={classes.typedContainer}>
        <Typography className={classes.title} variant="h3">
          <Typed
            strings={header == null ? ["Vocal for Local!!!"] : header}
            typeSpeed={40}
          />
        </Typography>
        <br />
        <Typography className={classes.subTitle} variant="h4">
          <Typed
            strings={props.subHeader != null ? props.subHeader : [""]}
            typeSpeed={40}
            backSpeed={60}
            loop
          />
        </Typography>
        <Button
          type="button"
          onClick={() => {
            window.location.href = "#rewards";
          }}
          color="inherit"
        >
          Click here for rewards !!!!!!
        </Button>
      </Box>
      <div></div>
      {/* <Particles
        canvasClassName={classes.particlesCanva}
        params={{
          particles: {
            number: {
              value: 50,
              density: {
                enable: true,
                value_area: 500,
              },
            },
            shape: {
              type: "circle",
              stroke: {
                width: 2,
                color: "grey",
              },
            },
            size: {
              value: 8,
              random: true,
              anim: {
                enable: true,
                speed: 10,
                size_min: 0.1,
                sync: true,
              },
            },
            color: {
              value: "#0d47a1",
            },
          },
        }}
      /> */}
    </div>
  );
}
