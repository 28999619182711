import { makeStyles } from "@material-ui/core";
import React from "react";
import ReactPlayer from "react-player/lazy";


const useStyle = makeStyles((theme) => ({
    playerWrapper: {
        position: "relative",
        paddingTop: "56.25%", /* 720 / 1280 = 0.5625 */
      },
      reactPlayer: {
        position: "absolute",
        top: "0",
        left: "0",
      },
}));

export default function VideoPlayer(props) {

    const classes = useStyle();
  // Lazy load the YouTube player
  return (
    <React.Fragment >
        <div className={classes.playerWrapper}>
      <ReactPlayer  className={classes.reactPlayer} loop={props.loop} playing ={props.isSelected} controls={props.controls} width={props.width} height={props.height} url={props.url} volume={null} muted={true}  />
      </div>
    </React.Fragment>
  );
}
