import { Typography } from "@material-ui/core";
import { Card, makeStyles } from "@material-ui/core";
import React from "react";
import { Carousel } from "react-responsive-carousel";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 1368,
      maxHeight: 620,
      justifyItems: "center",
    },
    // videoContainer: {
    //   alignItems="center"
    // justify="center"
    // }
  }));
  
export default function ImageSlider(props) {
  const classes = useStyles();
  const renderCarousal = () => {
    if (props.imageGrids.length > 0) {
      return props.imageGrids.map((i) => {
        return (
          <div key={i.toString()}>
              <Card className={classes.root}>
                  <img width="1368px" height="620px" src={i.img} alt={i.title} />
              </Card>
              
          </div>
        );
      });
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h3" align="center">
        Gallery
      </Typography>
      <br/>
      <br/>
    <Carousel
      autoPlay
      infiniteLoop
      showThumbs={false}
    >
      {renderCarousal()}
    </Carousel>
    </React.Fragment>
  );
}
