import React from "react";
import { Container, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export default function RefundPolicy() {
  return (
    <React.Fragment>
      <Container>
        <Typography variant="h3" align="center">
          JCU Refund Policy
        </Typography>

        <br />
        <br />
        <br />
        <Typography paragraph>
          The refund time period for different modes of payments is provided
          below.
        </Typography>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Available refund method</TableCell>
                <TableCell align="left">
                  <p>
                    <b>Refund Time-frame</b>
                  </p>
                  <p>
                    <b>
                      Product Seller/Shopkeeper (After Shops/Sellers notifies
                      JCU of receipt of return)
                    </b>
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  <b>Prepaid Orders</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  JCU Cashback Coin Balance
                </TableCell>
                <TableCell align="left">
                  <p>5 hours</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <p>Credit Card/ Debit Card</p>
                  <p>Net Banking Account (Credited to Bank Account)</p>
                </TableCell>
                <TableCell align="left">
                  <p>3-5 Business Days</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UPI Linked Bank Account
                </TableCell>
                <TableCell align="left">
                  <p>2-4 business days</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  <b>Pay on Delivery Orders</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  NEFT to Bank Account
                </TableCell>
                <TableCell align="left">
                  <p>3-5 Business Days</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  JCU Cashback Coin Balance
                </TableCell>
                <TableCell align="left">
                  <p>5 hours</p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        <Typography paragraph>
          If the standard time-frame as mentioned in the above table has expired
          and you have still not received the refund, please contact your credit
          or debit card issuer or your bank for more information. Refunds will
          not be processed in cash. Refunds can be processed via paper cheque
          only in exceptional cases.
        </Typography>
      </Container>
    </React.Fragment>
  );
}
