/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
//import { makeStyles } from "@material-ui/core/styles";

//import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
//import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from "@material-ui/icons/Home";
import { AppBar, CssBaseline, Toolbar, Typography } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     //flexGrow: 10,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

export default function NavBar(props) {
 // const classes = useStyles();

  let listener = null;
  const [scrollState, setScrollState] = useState("top");

  useEffect(() => {
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 500) {
        if (scrollState !== "scroll") {
          setScrollState("scroll");
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position={props.position}
        style={{
          backgroundColor:
            scrollState === "top" ? `${props.color}` : "rgb(22 20 60)",
        }}
      >
        <Toolbar>
          <IconButton onClick={()=> window.location.href="/"}>
            <HomeIcon color="primary"/>
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            {props.appTitle}
          </Typography>
          {/* <nav>
            <Link variant="button" color="inherit" align="right" href="#about" className={classes.link}>
              About
            </Link>
            <Link variant="button" color="inherit" align="right" href="#features" className={classes.link}>
              Features
            </Link>
            <Link variant="button" color="inherit" align="right" href="#contact" className={classes.link}>
              Contact Us
            </Link>
          </nav> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
