import React from "react";
import { Container, Typography } from "@material-ui/core";

export default function TermsConditions() {
  return (
    <React.Fragment>
      <Container>
        <Typography variant="h3" align="center">
          Terms and Conditions
        </Typography>
        <br />
        <br />
        <Typography paragraph>
          It is strongly recommended that you read and understand these ‘Terms
          of Use’ carefully, as by accessing this APP (hereinafter the
          “PRODUCT/SERVICE SECTION/ADVERTISEMENT”), you agree to be bound by the
          same and acknowledge that it constitutes an agreement between you and
          the Company (hereinafter the “User Agreement”). If you do not agree
          with this User Agreement, you should not use or access the
          PRODUCT/SERVICE SECTION/ADVERTISEMENT for any purpose whatsoever.
        </Typography>
        <Typography paragraph>
          This document is published in accordance with the provisions of Rule 3
          of the Information Technology (Intermediaries Guidelines) Rules, 2011.
          The User Agreement may be updated from time to time by the Company
          without notice. It is therefore strongly recommended that you review
          the User Agreement, as available on the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT, each time you access and/or use the
          PRODUCT/SERVICE SECTION/ADVERTISEMENT.
        </Typography>
        <Typography paragraph>
          The terms ‘visitor(s)’, ‘user(s)’, ‘you’ hereunder refer to the person
          visiting, accessing, browsing through and/or using the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT at any point in time.
        </Typography>
        <Typography variant="h5" align="left">
          Overview
        </Typography>
        <Typography paragraph>
          The PRODUCT/SERVICE SECTION/ADVERTISEMENT is a platform for domestic
          consumers to transact with third party sellers Or Service provider,
          who have been granted access to the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT to display and offer products for sale or
          service through the PRODUCT/SERVICE SECTION/ADVERTISEMENT. For
          abundant clarity, the Company does not provide any services to users
          other than providing the PRODUCT/SERVICE SECTION/ADVERTISEMENT as a
          platform to transact at their own cost and risk, and other services as
          may be specifically notified in writing.{" "}
        </Typography>
        <Typography paragraph>
          The Company is not and cannot be a party to any transaction between
          you and the third party sellers, or have any control, involvement or
          influence over the products purchased by you from such third party
          sellers or the prices of such products charged by such third-party
          sellers or availed any service from the vendor. The Company therefore
          disclaims all warranties and liabilities associated with any products
          offered on the PRODUCT/SERVICE SECTION/ADVERTISEMENT.{" "}
        </Typography>
        <Typography paragraph>
          Services on the PRODUCT/SERVICE SECTION/ADVERTISEMENT are available to
          only select geographies in India, and are subject to restrictions
          based on business hours and days of third party sellers and service
          providers.{" "}
        </Typography>
        <Typography paragraph>
          Transactions through the PRODUCT/SERVICE SECTION/ADVERTISEMENT may be
          subject to a delivery charge/Visiting charges/Accessing Charges where
          the minimum requirements are not met. You will be informed of such
          charges at the stage of check-out for a transaction through the
          PRODUCT/SERVICE SECTION/ADVERTISEMENT.
        </Typography>
        <Typography variant="h5" align="left">
          Eligibility
        </Typography>
        <Typography paragraph>
          Persons who are “incompetent to contract” within the meaning of the
          Indian Contract Act, 1872 including minors, un-discharged insolvents
          etc. are not eligible to use/access the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT.
        </Typography>
        <Typography paragraph>
          However, if you are a minor, i.e. under the age of 18 years, you may
          use/access the PRODUCT/SERVICE SECTION/ADVERTISEMENT under the
          supervision of an adult parent or legal guardian who agrees to be
          bound by these Terms of Use. You are however prohibited (even under
          provision) from purchasing any product(s) or availed any services
          which are for adult consumption, the sale of which to minors is
          prohibited.
        </Typography>
        <Typography paragraph>
          The PRODUCT/SERVICE SECTION/ADVERTISEMENT is intended to be a platform
          for end-consumers desirous of purchasing product(s) for domestic
          self-consumption & services for their needs. If you are a retailer,
          institution, wholesaler or any other business user, you are eligible
          to use the PRODUCT/SERVICE SECTION/ADVERTISEMENT to purchase products
          from third-party sellers, who have been granted access to the
          PRODUCT/SERVICE SECTION/ADVERTISEMENT to display and offer their
          products or provide their services through the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT.
        </Typography>
        <Typography paragraph>
          The Company, in its sole discretion and without liability, reserves
          the right to terminate or refuse your registration, or refuse to
          permit use/access to the PRODUCT/SERVICE SECTION/ADVERTISEMENT, if:
          (i) it is discovered or brought to notice that you do not conform to
          the eligibility criteria, or (ii) the Company has reason to believe
          (including through evaluating usage patterns) that the eligibility
          criteria is not met/is violated by a user, or (iii) may breach the
          terms of this User Agreement.
        </Typography>
        <Typography paragraph>
          In order to determine compliance with eligibility criteria, the
          Company inter alia uses an algorithm and/or pre-determined criteria
          based technology and accordingly, from time to time, your usage may be
          restricted or blocked on account of overlap with such
          algorithms/predetermined criteria. In such cases, if you are a genuine
          domestic user of the Platform, please contact us for assistance in the
          mentioned below
        </Typography>
        <Typography paragraph>
          Email id- compliance.support@snp.justconnectunite.com
        </Typography>
        <Typography variant="h5" align="left">
          License and Access
        </Typography>
        <Typography paragraph>
          The Company grants you a limited sub-license to access and make
          personal use of the PRODUCT/SERVICE SECTION/ADVERTISEMENT, but not to
          download (other than page caching) or modify it, or any portion of it,
          except with express prior written consent of the Company. Such limited
          sublicense does not include/permit any resale or commercial use of the
          PRODUCT/SERVICE SECTION/ADVERTISEMENT or its contents; any collection
          and use of any product listings, descriptions, or prices; any
          derivative use of the PRODUCT/SERVICE SECTION/ADVERTISEMENT or its
          contents; any downloading or copying of information for the benefit of
          another third party; or any use of data mining, robots, or similar
          data gathering and extraction tools. The PRODUCT/SERVICE
          SECTION/ADVERTISEMENT or any portion of the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT may not be reproduced, duplicated, copied, sold,
          resold, visited, or otherwise exploited for any commercial purpose
          without express prior written consent of the Company. You may not
          frame or utilize framing techniques to enclose any trademark, logo, or
          other proprietary information (including images, text, page layout, or
          form) of the PRODUCT/SERVICE SECTION/ADVERTISEMENT or of the Company
          and/or its affiliates without the express prior written consent of the
          Company. You may not use any meta tags or any other “hidden text”
          utilizing the Company’s name or trademarks without the express prior
          written consent of the Company. You shall not attempt to gain
          unauthorized access to any portion or feature of the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT, or any other systems or networks connected to
          the PRODUCT/SERVICE SECTION/ADVERTISEMENT or to any server, computer,
          network, or to any of the services offered on or through the
          PRODUCT/SERVICE SECTION/ADVERTISEMENT, by hacking, ‘password mining’
          or any other illegitimate means.
        </Typography>
        <Typography paragraph>
          You hereby agree and undertake not to host, display, upload, modify,
          publish, transmit, update or share any information
        </Typography>
        <ul>
          <li>
            Belongs to another person and to which you do not have any right to
            do it.
          </li>
          <li>Harms minors in any way.</li>
          <li>
            Infringes any patent, trademark, copyright, or another
            proprietary/intellectual property right.
          </li>
          <li>Violates any law for the time being in force.</li>
          <li>
            Is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, pedophilic, libelous, invasive of another’s privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating, or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatever.
          </li>
          <li>
            Deceives or misleads the addressee about the origin of such messages
            and communicates any information which is grossly offensive or
            menacing in nature.
          </li>
          <li>Impersonates another person.</li>
          <li>
            Contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any Mobile resource
          </li>
          <li>
            Threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offense or
            prevents investigation of any offense or is insulting any other
            nation.
          </li>
          <li>Is misleading or known to be false in any way.</li>
          <li>
            Any unauthorized use shall automatically terminate the permission or
            sub-license granted by the Company.
          </li>
        </ul>
        <Typography variant="h5" align="left">
          Account and Registration Obligations
        </Typography>
        <Typography paragraph>
          All users must register and log in for placing orders on the
          PRODUCT/SERVICE SECTION/ADVERTISEMENT. You must keep your account and
          registration details current and correct for all communications
          related to your purchases from the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT. By agreeing to the Terms of Use, the user
          agrees to receive promotional or transactional communication and
          newsletters from the Company and its partners. The user can opt out
          from such communication and/or newsletters either by or by contacting
          the customer services team of PRODUCT/SERVICE SECTION/ADVERTISEMENT
          and placing a request for the same and for further details check
          website “http://app.justconnectunite.com” in contact support section.
        </Typography>
        <Typography paragraph>
          As part of the registration process on the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT, the Company may collect the following
          personally identifiable information about you, including but not
          limited to Name , email address, age, address mobile phone number and
          other contact details, demographic profile (like your age, gender,
          occupation, education, address).
        </Typography>{" "}
        <Typography paragraph>
          Also information about the pages on the PRODUCT/SERVICE
          SECTION/ADVERTISEMENT you visit/access, the links you click on the
          PRODUCT/SERVICE SECTION/ADVERTISEMENT, the number of times you access
          a particular page/feature and any such information.
        </Typography>
        <Typography paragraph>
          Information collected about you is subject to the Privacy Policy of
          the Company
          (http://app.justconnectunite.com:3030/passWeb/privacy-policy ), which
          is incorporated in these Terms of Use by reference.
        </Typography>
        <Typography variant="h5" align="left">
          JCU Cashbacks
        </Typography>
        <Typography paragraph>
          {" "}
          These terms and conditions relating to eligibility for Cashback shall
          be in addition to and not in substitution of or derogation to the
          terms and conditions governing the use/access of PRODUCT/SERVICE
          SECTION/ADVERTISEMENT.
        </Typography>
        <ul>
          <li>
            A user shall be entitled to cashback on a purchase/order or any
            availed service as indicated at the time of checkout of the final
            order on the PRODUCT/SERVICE SECTION/ADVERTISEMENT.
          </li>
          <li>
            No Cashback shall be available for orders which are canceled. Any
            Cashback offered may be recovered by the Company, without notice to
            the user, in such cases. Any Cashback offered for orders for which a
            refund claim is made, whether in part or in full, shall be adjusted
            to remove Cashback attributable to the extent of the order refunded.
            This condition shall apply strictly and notwithstanding the display
            of cashback on any section listed on the Application as an Product /
            services/ Advertisement section cashback.
          </li>
          <li>
            Cashback shall mean the grant of credit for the amount of eligible
            cashback, shall be added to the users ‘Promotional/Cashback’ section
            of his/her ‘JCU Money’ account, and shall in no event imply that the
            cashback shall be credited to a card account or refunded in cash at
            any time. But users can raise a request to the JCU support team
            “support@snp.justconnectunite.com or via raising a ticket in the App
            support section to transfer the balance to the user's (his/her) bank
            account. Some charges will be applicable and can from time to time
            depend on company terms and conditions, as users will be notified
            during the request for Ticket generation. JCU money is powered by
            xxxx and use thereof is subject to additional terms and conditions.
          </li>
          <li>
            Cashback offers can be clubbed with any other offers and cannot be
            assigned/ transferred to any other person.
          </li>
          <li>
            The Company reserves the right to, without liability or prejudice to
            any of its other rights, at any time, without previous notice and
            from time to time, withdraw/suspend / amend/cancel the Cashback
            offers, and the terms applicable thereto.
          </li>
          <li>
            Cashback will reflect in a user’s JCU Money within 7-10 days;
            however, for some coupons it can take 10 working days from the
            delivery of the order or any service availed for the cashback to
            reflect. Cashback credit available is usable only on the Application
            and no other platform, website, store or otherwise. Promotional
            cashback credits can expire at the discretion of JCU.
          </li>
          <li>
            Cashback will expire after a minimum duration of 360 days from date
            of registration unless specified or added through a separate
            campaign, which can be subject to a shorter expiry time.
          </li>
          <li>
            Users shall not hold the (Company or its group entities, or
            affiliates, their respective directors, officers, employees, agents)
            ‘responsible for’ or ‘liable for’ any actions, claims, demands,
            losses, damages, costs, charges and expenses which a user claims to
            have suffered, sustained or incurred, or claims to suffer, sustain
            or incur, ‘by way of’ and/or ‘on account of’ any purchase made
            through the Application, whether with or without Cashback.
          </li>
          <li>
            The Company does not in any manner endorse or support or promote, in
            any specific manner, the purchase of products or undertaking of
            transactions inter alia which are subject to Cashbacks.
          </li>
          <li>
            Cashback and discount codes are not applicable on any such products
            where a third-party seller doesn’t want to provide but cashback
            amount can be used from JCU Money while placing the order.
          </li>
        </ul>
      </Container>
    </React.Fragment>
  );
}
