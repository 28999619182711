import React from "react";
import { Container, Typography } from "@material-ui/core";
//import { List, ListItemText, ListItem } from "@material-ui/core";

export default function ReplacementPolicy() {
  return (
    <React.Fragment>
      <Container>
        <Typography variant="h3" align="center">
          Replacement Policy
        </Typography>

        <br />
        <br />
        <br />
        <Typography variant="h5" align="left">
          What are the conditions for Free Replacement?
        </Typography>
        <Typography paragraph>
          Items within the return window and in stock (exact same item) with the
          same shopkeeper are eligible for free replacement. The free
          replacement order will be handled via raising ticket and then will be
          further acted upon once the original order is returned to the
          shopkeeper. Free replacements can be requested if the following
          conditions apply:
        </Typography>

        <ul>
          <li>Item received is physically damaged;</li>
          <li>Item received has missing parts or accessories;</li>
          <li>
            Items received are different from their description on the product
            e-cart detail page of shopkeeper but if via Physical Scan memo or
            local input cart then replacement is possible in a day only.
          </li>
          <li>Items received are defective/does not work properly.</li>
        </ul>
        <Typography variant="h5" align="left">
          Note:
        </Typography>
        <ul>
          <li>
            A free replacement cannot be created for an item which was returned
            and replaced once earlier.
          </li>
          <li>
            If the item has missing parts or accessories, you may try to contact
            the Shopkeeper for assistance. Shopkeeper contact information can
            usually be found on the item packaging or in the paperwork included
            with the item.
          </li>
          <li>
            If your item is not eligible for free replacement due to any reason,
            you can always return it for a refund.
          </li>
          <li>
            A replacement can only be created if the product is available with
            the same shopkeeper. In case, the product is not available with the
            same shopkeeper, please "Contact Shopkeeper” or JCU to request a
            refund.
          </li>
        </ul>
      </Container>
    </React.Fragment>
  );
}
