import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//import { List, ListItemText, ListItem } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});

export default function ReturnPolicy() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container>
        <Typography variant="h3" align="center">
          JCU Return Policy
        </Typography>

        <br />
        <br />
        <br />

        <Typography paragraph>
          All the services taken from Just Connect Unite ‘Service Category’ are
          non-refundable and if any issue in services offered by the Service
          Owner, JCU Team can help in creating a channel between Customer and
          Business and nonetheless be responsible further if Business disagreed
          or refund was not provided. Based on further complaints and internal
          assessment the Service owner will be barred from providing service.
        </Typography>

        <Typography paragraph>
          Most items purchased from shopkeepers listed on Just Connect Unite
          ‘Product Category’ are returnable within the return window (i.e for
          most cases 2 days when shops belong to Customer Local area and 7 days
          when shops are remote from customer i.e radius above 60 km), except
          those that are explicitly identified as not returnable like all the
          eatable items (i.e Manufactured locally and un-branded and unpacked
          items like Local Sweets, Restaurant, Locally Prepared Food ),
          Cosmetics, Medicine, Grocery (Vegetable and Fruits).
        </Typography>

        <ul>
          <li>
            It is determined that the product was not damaged while in your
            possession,
          </li>
          <li>
            The product is not different from what was delivered/shipped to you,
          </li>
          <li>
            The product is returned in original condition (with
            brand’s/manufacturer's box, MRP tag intact, user manual, warranty
            card and accessories)
          </li>
        </ul>
        <Typography paragraph>
          For the products that are returned by the customer, the refund is
          issued to the original payment method (in case of pre-paid
          transactions) or to the bank account / as JC Pay Cashback Coin balance
          (in case of Pay on Delivery orders), the details for making such
          refund and the timelines are detailed in the refund policy available{" "}
          <a target="_blank" href="/refund-policy">
            here
          </a>
          .
        </Typography>
        <ul>
          <li>General Returns Policy</li>
          <li>Beauty, Health, and Groceries</li>
        </ul>
        <Typography variant="h5" align="left">
          1.General Returns Policy
        </Typography>
        <Typography paragraph>
          1. Products are returnable within the applicable return window if
          you’ve received them in a condition that is physically damaged, has
          missing parts or accessories, defective or different from their
          description on the product detail page on Local/Remote Shopkeepers
          E-Cart but if Product procured from local shop by visiting directly or
          via memo based order and the if Local shop then return policy is not
          applicable.
        </Typography>
        <Typography paragraph>
          2. If you report an issue with your Electronics goods like Smartphone,
          Tablet, Laptop, Television, Air Conditioner, Refrigerator, Washing
          Machine, Microwave, procured from Local Shop are returnable within a
          receipt of Product we may schedule a negotiation call with Local Shop
          and its their discretion to send technician visit to your location.
          This visit is locally handled between shop and customer and JCU will
          only help customers in case of escalation, to schedule a Local
          Technician and later action will be based on resolution provided in
          technician's evaluation report. In case of Remote (i.e above 60 km
          from customer location), JCU will schedule Local Technician and based
          on technician's evaluation report will help in Return, Refund and
          Replacement of Product with Seller/Shopkeeper.
        </Typography>
        <Typography paragraph>3. Return will be processed only if:</Typography>
        <ul>
          <li>
            it is determined that the product was not damaged while in your
            possession
          </li>
          <li>the product is not different from what was shipped to you</li>
          <li>
            3.3. the product is returned in original condition (with
            brand’s/manufacturer's box, MRP tag intact, user manual, warranty
            card and all the accessories therein)
          </li>
        </ul>
        <Typography paragraph>
          4. If you wish to return an electronic device that stores any personal
          information, please ensure that you have removed all such personal
          information from the device prior to returning. JCU shall not be
          liable in any manner for any misuse or usage of such information.
        </Typography>
        <Typography paragraph>
          5. Products marked as "non-returnable" on the product detail page of
          Shopkeeper’s E-cart cannot be returned.
        </Typography>
        <Typography paragraph>
          6. Additional information is not required to return an eligible order
          unless otherwise noted in the category specific policy.
        </Typography>
        <Typography paragraph>
          7. Products may be eligible for replacement only if the same
          shopkeeper has the exact same item in stock.
        </Typography>
        <Typography paragraph>
          8. If the replacement request is placed and the shopkeeper does not
          have the exact same product in stock, JCU will initiate a refund
          policy with help of the shopkeeper.
        </Typography>

        <Typography paragraph>
          <b>Note:</b> If you've received a non-returnable product in a
          damaged/defective condition, you can contact us within 1 days from the
          delivery of the product.
        </Typography>
        <Typography variant="h5" align="left">
          2. Beauty, Health, and Groceries
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell align="left">Return Policy</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Beauty
                </TableCell>
                <TableCell align="left">
                  <b>Non Returnable</b>
                  <p>
                    This item is non-returnable due to the hygiene and personal
                    care nature of the product.
                  </p>
                  <p>
                    However, in the unlikely event of damaged, defective or
                    different items delivered to you, we will provide a full
                    refund or free replacement as applicable.
                  </p>
                  <p>
                    We may contact you to ascertain the damage or defect in the
                    product prior to issuing refund/replacement.
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Sports Nutrition (Whey Protein Powders and related Diet
                  Supplements),
                </TableCell>
                <TableCell align="left">
                  <b>2 Days Returnable</b>
                  <p>
                    This item is eligible for free replacement, within 2 days of
                    delivery, in an unlikely event of damaged, defective or
                    different item delivered to you. You can also return the
                    product within 2 days of delivery for a full refund.
                  </p>
                  <p>
                    Please keep the item in its original condition, with outer
                    box or case, user manual, warranty cards, and other
                    accompaniments in manufacturer packaging for a successful
                    return pick-up. We may contact you to ascertain the damage
                    or defect in the product prior to issuing
                    refund/replacement.
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Mobility and Disability Aids, Joint Supports, Wheelchairs etc.
                </TableCell>
                <TableCell align="left">
                  <b>2 Days Returnable</b>
                  <p>
                    This item is eligible for free replacement, within 2 days of
                    delivery, in an unlikely event of damaged, defective or
                    different item delivered to you. You can also return the
                    product within 2 days of delivery for a full refund.
                  </p>
                  <p>
                    Please keep the item in its original condition, with outer
                    box or case, user manual, warranty cards, and other
                    accompaniments in manufacturer packaging for a successful
                    return pick-up. We may contact you to ascertain the damage
                    or defect in the product prior to issuing
                    refund/replacement.
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <p>
                    Household Supplies: Laundry Products (Detergents, Fabric
                    Conditioners), Household Cleaners (Toilet Cleaners, Floor
                    Cleaners, Hand washes, Tissues), Air Fresheners (Sprays,
                    Gels) etc.
                  </p>{" "}
                  <p>
                    Personal Care: Oral Care (Toothbrushes, toothpastes,
                    mouthwashes etc); Feminine Hygiene (Sanitary Pads, Panty
                    Liners, Menstrual Cups etc.); Shaving and Hair Removal
                    (Men’s Shaving – Razors, Blades, Shaving Foams, Brushes
                    etc.; Men’s Beard Care – Beard Oil, Beard Serum etc.;
                    Women’s Hair Removal – Wax Strips, Creams, Razors etc)
                  </p>
                  <p>
                    Family Nutrition: (Infant Baby Food, Toddlers’ and Kids’
                    Health Drinks)
                  </p>{" "}
                  <p>
                    Vitamin and Mineral Supplements: (Core Letter Vitamins,
                    Multi-Vitamins)
                  </p>
                  <p>Contact Lenses and Related Products</p>
                  <p>
                    Health Care Products: Ayurveda Products, Pain Relief
                    Products, Herbal Supplements, Medical Supplies, Adult
                    Diapers
                  </p>
                </TableCell>
                <TableCell align="left">
                  <b>Non Returnable</b>
                  <p>
                    This item is non-returnable due to hygiene and consumable
                    nature of the product.
                  </p>
                  <p>
                    However, in the unlikely event of damaged, defective or
                    different items delivered to you, we will provide a full
                    refund or free replacement as applicable.
                  </p>
                  <p>
                    We may contact you to ascertain the damage or defect in the
                    product prior to issuing refund/replacement.
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Medicines
                </TableCell>
                <TableCell align="left">
                  <b>Non Returnable</b>
                  <p>
                    This item is non-returnable due to hygiene and consumable
                    nature of the product.
                  </p>
                  <p>
                    However, in the unlikely event of damaged, defective or
                    different items delivered to you, we will provide a full
                    refund or free replacement as applicable. We may contact you
                    to ascertain the damage or defect in the product prior to
                    issuing refund/replacement.
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Personal Care Appliances: Shavers, Trimmers, Hair Dryers,
                  Straighteners; Skin care, Nail care, Medical equipments
                </TableCell>
                <TableCell align="left">
                  <b>2 Days Replacement only</b>
                  <p>
                    This item is eligible for free replacement, within 2 days of
                    delivery, in an unlikely event of damaged, defective or
                    different item delivered to you.
                  </p>
                  <p>
                    You can also return the product within 2 days of delivery
                    for a full refund. Please keep the item in its original
                    condition, with outer box or case, user manual, warranty
                    cards, and other accompaniments in manufacturer packaging
                    for a successful return pick-up.
                  </p>
                  <p>
                    We may contact you to ascertain the damage or defect in the
                    product prior to issuing refund/replacement.
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Personal Care Appliances: Oral Care Products and Appliances
                </TableCell>
                <TableCell align="left">
                  <b>Non Returnable</b>
                  <p>
                    This item is non-returnable due to hygiene/health and
                    personal care/wellness/consumable nature of the product.
                    However, in the unlikely event of damaged, defective or
                    different item delivered to you, we will provide a full
                    refund or free replacement as applicable.
                  </p>
                  <p>
                    We may contact you to ascertain the damage or defect in the
                    product prior to issuing refund/replacement.
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Grocery & Gourmet
                </TableCell>
                <TableCell align="left">
                  <b> Non Returnable</b>
                  <p>
                    This item is non-returnable due to consumable nature of the
                    product. However, in the unlikely event of damaged,
                    defective or different item delivered to you, we will
                    provide a full refund or free replacement as applicable.
                  </p>
                  <p>
                    We may contact you to ascertain the damage or defect in the
                    product prior to issuing refund/replacement.
                  </p>
                </TableCell>
              </TableRow>
            
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </React.Fragment>
  );
}
