import React from "react";
import {
  Grid,
  Card,
  Typography,
  CardMedia,
  CardContent,
  makeStyles,
  Box,
} from "@material-ui/core";
//import Container from '@material-ui/core/Container'

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: 290,
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    width:300,
    maxWidth: 451
  }
}));

export default function Features(props) {
  const classes = useStyle();

  return (
    <div>
      <div id="features">
        <Typography variant="h3" align="center">
          Features
        </Typography>
        <br />
      </div>
      <Grid container spacing={2}>
        {props.featuresList != null
          ? props.featuresList.map((f) => {
              return (
                <React.Fragment key={f.title.toString()}>
                  <Grid item xs={12} md={6}>
                    <Card className={classes.root} elevation={15}>
                      <CardMedia
                        className={classes.cover}
                        image={f.image}
                        title="JCU Feature's Image"
                        height="200"
                        width="200"
                      />
                      <CardContent>
                      {/* <CardMedia
                        className={classes.cover}
                        image="https://source.unsplash.com/random"
                        title="Live from space album cover"
                      /> */}
                        <Typography align="center" component="div">
                          <Box fontStyle="Italic" fontSize="h5.fontSize">
                            {f.title}
                          </Box>
                        </Typography>
                        <br />
                        <Typography variant="body2" paragraph>
                          {f.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </React.Fragment>
              );
            })
          : ""}
      </Grid>
    </div>
  );
}
