import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Agents from './pages/AgentsCareers';
import Careers from './pages/Careers';
import DeliveryPolicy from './pages/DeliveryPolicy';
import Employee from './pages/EmployeeCareers';
import Home from './pages/Home'
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import ReplacementPolicy from './pages/ReplacementPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import TermsConditions from './pages/TermsConditions';
import VideoFrame from './pages/VideoFrame';

export default function App() {
    
    return(
        <React.Fragment>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact component={Home}  />
                    <Route path="/careers"  exact component={Careers} />
                    <Route path="/careers/employee"  exact component={Employee} />
                    <Route path="/careers/agents"  exact component={Agents} />
                    <Route path="/privacy-policy"  exact component={PrivacyPolicy} />
                    <Route path="/refund-policy"  exact component={RefundPolicy} />
                    <Route path="/return-policy"  exact component={ReturnPolicy} />
                    <Route path="/replacement-policy"  exact component={ReplacementPolicy} />
                    <Route path="/delivery-policy"  exact component={DeliveryPolicy} />
                    <Route path="/terms-condition"  exact component={TermsConditions} />
                    <Route path="/play-video"  exact component={VideoFrame} />
                    <Route/>
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
}