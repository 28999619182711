import React, { useState, useEffect } from "react";
import { Container, CssBaseline, Typography } from "@material-ui/core";
import NavBar from "../components/header/NavBar";
import CareersForm from "./CareersForm";
import Footer from "../components/Footer";

export default function Agents() {
  const [careersAgent, setCareersAgent] = useState([]);

  const fetchCareersAData = () => {
    fetch("../data/careersData.json")
      .then((res) => {
        console.log(res, " resC");
        return res.json();
      })
      .then((d) => {
        //setData(d)
        console.log(d, " resCd");
        setCareersAgent(d.careersAgent);
      })
      .catch((err) => {
        console.log(err, " error");
      });
  };

  useEffect(() => {
    fetchCareersAData();
  }, []);
  const renderCareersAgent = () => {
    if (careersAgent.length > 0) {
      return careersAgent.map((s) => {
        return (
          <Typography key={s.toString()} paragraph>
            {s}
          </Typography>
        );
      });
    } else {
      return <p>Loading Data ....</p>;
    }
  };

  const renderAgentCareerForm = () => {
    return <CareersForm/>
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <NavBar postition={"sticky"} appTitle={"Just Connect Unite"} color={"rgb(22 20 60)"} />
      <br/>
      <br/>
      <br/>
      <br/>
      <Container>
        <Typography variant="h3" align="center">
          Agents
        </Typography>
        <br />
        <br />
        {renderCareersAgent()}
        <br />
        <br />
        {renderAgentCareerForm()}
      </Container>
      <br/>
      <Footer />
    </React.Fragment>
  );
}
