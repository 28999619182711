import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormGroup,
  Checkbox,
  makeStyles,
  FormHelperText,
  Collapse,
  IconButton,
  // makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
// import { Link } from "react-router-dom";
import DialogBox from "../components/DialogBox";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import ContactUsSevice from "../services/ContactUsSevice";
import Loader from "../components/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 0,
  },
}));

export default function ContactUsForm() {
  const classes = useStyles();
  const [contactDetails, setContactDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    city: "",
    zipCode: "",
    commentsType: "",
    comments: "",
    privacyAccepted: false,
  });

  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    " Please fill all the required details!"
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [pinError, setPINError] = useState(false);
  const [commentsTypeError, setCommentsTypeError] = useState(false);
  const [commentsError, setCommentsError] = useState(false);
  const [privacyCheckedError, setPrivacyCheckedError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertOpen, setAlertOpen] = useState(true);
  const handleChange = (event) => {
    setError(false);
    const { name, value } = event.target;
    setContactDetails({
      ...contactDetails,
      [name]: value,
    });
  };
  const clearContactDetails = () => {
    setContactDetails({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      city: "",
      zipCode: "",
      commentsType: "",
      comments: "",
      privacyAccepted: false,
    });
  };
  const handlePrivacyAccepted = (event) => {
    setContactDetails({
      ...contactDetails,
      privacyAccepted: event.target.checked,
    });
  };
  const renderDialogContent = () => {
    if (showPrivacy) {
      console.log("showing privacy policy....");
      return (
        <React.Fragment>
          <PrivacyPolicy />
        </React.Fragment>
      );
    } else if (showTerms) {
      return (
        <React.Fragment>
          <TermsConditions />
        </React.Fragment>
      );
    }
  };
  const renderDialogActions = () => {
    return (
      <Button variant="contained" color="primary" onClick={handleDialogClose}>
        Close
      </Button>
    );
  };
  const handleDialogClose = () => {
    setShowTerms(false);
    setShowPrivacy(false);
  };
  const validateForm = () => {
    let isError = false;
    if (contactDetails.firstName === "") {
      isError = true;
      setFirstNameError(true);
    }
    if (contactDetails.mobile === "") {
      isError = true;
      setMobileError(true);
    }
    if (contactDetails.email === "") {
      isError = true;
      setEmailError(true);
    }
    if (contactDetails.city === "") {
      isError = true;
      setCityError(true);
    }
    if (contactDetails.zipCode === "") {
      isError = true;
      setPINError(true);
    }
    if (contactDetails.commentsType === "") {
      isError = true;
      setCommentsTypeError(true);
    }
    if (contactDetails.comments === "") {
      isError = true;
      setCommentsError(true);
    }
    if (contactDetails.privacyAccepted === false) {
      isError = true;
      setPrivacyCheckedError(true);
    }
    return isError;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let isError = validateForm();
    setError(isError);
    setAlertOpen(false);
    if (!isError) {
      setBtnDisabled(true);
      setShowLoader(true);
      ContactUsSevice.saveUserContactDetailsData(contactDetails)
        .then((result) => {
          console.log("Data Saved Successfully....");
          clearContactDetails();
        })
        .catch(() => {
          setError(true);
          setErrorMsg("Something went wrong! Please try again.");
        });
      if (!error) {
        ContactUsSevice.sendMailToUser(contactDetails)
          .then((res) => {
            console.log("User mail sent Successfully....");
            console.log("Res: ", res);
            console.log("Resd: ", res.data);
            setBtnDisabled(false);
            setShowLoader(false);
            setSuccess(true);
          })
          .catch(() => {
            setError(true);
            setErrorMsg("Something went wrong! Please try again.");
            setBtnDisabled(false);
            setShowLoader(false);
          });
      }
      ContactUsSevice.sendInternalMail(contactDetails)
        .then((res) => {
          console.log("Internal mail sent Successfully....");
          console.log("Res: ", res);
          console.log("Resd: ", res.data);
          setBtnDisabled(false);
          setShowLoader(false);
          setSuccess(true);
        })
        .catch(() => {
          setBtnDisabled(false);
          setShowLoader(false);
        });
    }
  };

  return (
   <React.Fragment>
      <br />
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="First name"
              value={contactDetails.firstName}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setFirstNameError(false);
              }}
              error={firstNameError}
              fullWidth
              variant="outlined"
              //autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Last name"
              value={contactDetails.lastName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              //autoComplete="family-name"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              value={contactDetails.email}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setEmailError(false);
              }}
              error={emailError}
              fullWidth
              type="email"
              variant="outlined"
              //autoComplete="shipping postal-code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="mobile"
              name="mobile"
              label="Mobile No."
              value={contactDetails.mobile}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setMobileError(false);
              }}
              error={mobileError}
              fullWidth
              variant="outlined"
              //autoComplete="shipping country"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="City"
              value={contactDetails.city}
              onChange={handleChange}
              fullWidth
              type="text"
              variant="outlined"
              onFocus={() => {
                setError(false);
                setCityError(false);
              }}
              error={cityError}
              //autoComplete="shipping postal-code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zipCode"
              name="zipCode"
              label="PIN Code"
              value={contactDetails.zipCode}
              onChange={handleChange}
              fullWidth
              type="text"
              variant="outlined"
              onFocus={() => {
                setError(false);
                setPINError(false);
              }}
              error={pinError}
              //autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              required
              component="fieldset"
              error={commentsTypeError}
            >
              <FormLabel component="legend">Comment Type</FormLabel>
              <RadioGroup
                row
                aria-label="type"
                name="commentsType"
                value={contactDetails.commentsType}
                onChange={handleChange}
                onFocus={() => {
                  setError(false);
                  setCommentsTypeError(false);
                }}
              >
                <FormControlLabel
                  value="query"
                  control={<Radio color="primary" />}
                  label="Query"
                />
                <FormControlLabel
                  value="issue"
                  control={<Radio color="primary" />}
                  label="Issue"
                />
                <FormControlLabel
                  value="feedback"
                  control={<Radio color="primary" />}
                  label="FeedBack"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {/* <TextareaAutosize
            required
            id="comments"
            name="comments"
            label="Issues/Feedback"
            rowsMin={3}
            rowsMax={4}
            
          /> */}
            <TextField
              required
              id="comments"
              name="comments"
              label="Comments"
              value={contactDetails.comments}
              onChange={handleChange}
              onFocus={() => {
                setError(false);
                setCommentsError(false);
              }}
              fullWidth
              multiline
              rowsMax={5}
              variant="outlined"
              error={commentsError}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography paragraph>
              By submiting details you are agreeing to our{" "}
              <Button
                color="primary"
                onClick={() => {
                  setShowTerms(true);
                }}
              >
                Terms & Condition
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={privacyCheckedError}
              component="fieldset"
            >
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contactDetails.privacyAccepted}
                      onChange={handlePrivacyAccepted}
                      onFocus={() => {
                        setError(false);
                        setPrivacyCheckedError(false);
                      }}
                      name="privacyChecked"
                      color="primary"
                    />
                  }
                  //label="I agree and accept"
                  //labelPlacement="end"
                  //value="end"
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowPrivacy(true);
                    }}
                  >
                    Privacy Policy
                  </Button>
                </FormControlLabel>
                <Typography paragraph className={classes.root}>
                  I agree and accept{" "}
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowPrivacy(true);
                    }}
                  >
                    Privacy Policy
                  </Button>
                </Typography>
                <br />
                {/* <FormHelperText component="p">
                  Please Accept Privacy and Policy!
                </FormHelperText> */}
              </FormGroup>
            </FormControl>
          </Grid>
          <DialogBox
            showDialog={showPrivacy || showTerms}
            dialogContent={renderDialogContent()}
            dialogActions={renderDialogActions()}
            onClose={handleDialogClose}
          ></DialogBox>
          <Grid item xs={12}>
            {showLoader && <Loader />}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={btnDisabled}
              //className={classes.submit}
            >
              Submit
            </Button>
            <br />
            <br />
            {error && (
              <Alert variant="outlined" severity="error">
                {errorMsg}
              </Alert>
            )}
            {success ? (
              <Collapse in={alertOpen}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Thanks for Contacting!
                </Alert>
              </Collapse>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </form>
      </React.Fragment>
  );
}
