import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import CareerEmpImage from "../assets/images/career-emp.jpg";
import CareerAgentImage from "../assets/images/career-agent.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //minWidth: 375,
    minHeight: 275,
    textDecorationColor: "white",
    justifyContent: "center",
  },
  employee: {
    backgroundColor: "#00d5ff78",
  },
  agent: {
    backgroundColor: "#aaaaaaad",
  },
  media: {
    height: 240,
  },
  actionArea: {
    justifyContent: "center",
  },

  control: {
    padding: theme.spacing(2),
  },
}));

export default function Careers() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="h3" align="center">
        Careers
      </Typography>
      <br />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs={12} md={6}>
          <Card className={classes.root}>
           
              <CardMedia
                className={classes.media}
                component="img"
                src={CareerEmpImage}
                title="Employee Careers"
              />
              <CardContent>
                <Typography variant="h4" align="center">
                  Employee
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  JCU has a broad spectrum of career in mainly 3 wings -
                  Marketing Management, Technical (Android Developer, Server
                  Developer, Web Developer), Advertisement Management Portfolio.
                </Typography>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      window.location.href = "/careers/employee";
                    }}
                  >
                    Learn More
                  </Button>
                </CardActions>
                {/* <Paper className={classes.paper} /> */}
              </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.root}>
              <CardMedia
                className={classes.media}
                component="img"
                src={CareerAgentImage}
                title="Agents Careers"
              />
              <CardContent className={classes.agents}>
                <Typography variant="h4" align="center">
                  Agents
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  In contracts of agency, a legal relationship exists between
                  two people where one person acts on behalf of the other. For
                  our case an agent contracted to do business on behalf of JCU
                  product.
                </Typography>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      window.location.href = "/careers/agents";
                    }}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* <Grid container direction="row" justify="flex-end" alignItems="flex-end">
      <Grid item xs={12} md={6}>
          
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}
