import React from "react";
import {
  Box,
  Container,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));
export default function Footer() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="span"
          >
            Please Like, Follow, Subscribe us on Social Platform for latest
            updates.
          </Typography>
          &nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCwQCwZvSeTDGtIE5rPOLaKg"
          >
            <img
              height="20px"
              width="20px"
              src="http://justconnectunite.com/images/Youtube_LOGO.png"
              alt="Youtube"
            />
            &nbsp;
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/Just-Connect-Unite-101868548789106"
          >
            <img
              height="20px"
              width="20px"
              src="http://justconnectunite.com/images/FB_Logo.png"
              alt="Facebook"
            />
            &nbsp;
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://instagram.com/justconnectunite_snp?utm_medium=copy_link"
          >
            <img
              height="20px"
              width="20px"
              src="http://justconnectunite.com/images/Insta_Logo.png"
              alt="Instagram"
            />
            &nbsp;
          </a>
          <br/>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="span"
          >
            Visit Our :
          </Typography>
          <Link
            color="inherit"
            noWrap
            variant="body2"
            target="_blank"
            href={"/privacy-policy"}
            className={classes.toolbarLink}
          >
            Privacy Policy
          </Link>
          &nbsp; &nbsp; &nbsp;
          <Link
            color="inherit"
            noWrap
            variant="body2"
            target="_blank"
            href={"/terms-condition"}
            className={classes.toolbarLink}
          >
            Terms & Condition
          </Link>
          &nbsp;  &nbsp; &nbsp;
          <Link
            color="inherit"
            noWrap
            variant="body2"
            target="_blank"
            href={"/refund-policy"}
            className={classes.toolbarLink}
          >
            Refund Policy
          </Link>
          &nbsp;  &nbsp; &nbsp;
          <Link
            color="inherit"
            noWrap
            variant="body2"
            href={"/return-policy"}
            target="_blank"
            className={classes.toolbarLink}
          >
            Return Policy
          </Link>
          &nbsp;  &nbsp; &nbsp;
          <Link
            color="inherit"
            noWrap
            variant="body2"
            href={"/replacement-policy"}
            target="_blank"
            className={classes.toolbarLink}
          >
            Replacement Policy
          </Link>
          &nbsp;  &nbsp; &nbsp;
          <Link
            color="inherit"
            noWrap
            variant="body2"
            href={"/delivery-policy"}
            target="_blank"
            className={classes.toolbarLink}
          >
            Shipping and Delivery Policy
          </Link>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link
              color="inherit"
              href="http://snpnextgen.com"
              target="_blank"
              rel="noreferrer"
            >
              Spark N Pass Nextgen
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Container>
      </footer>
    </React.Fragment>
  );
}
