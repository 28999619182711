import axios from "axios";
import {
  BASE_JCU_SITE_SERVICE_URL,
  JCU_MAIL_SENDER_API_ENDPOINT,
  //JCU_MAIL_SERVIC_URL,
  SAVE_CAREERS_EMPLOYEE_DATA_ENDPOINT,
} from "../constants/Constants";

class CareersService {
  sendInternalMail(formData, file) {
   
    const message = `<html><body> Hi Team,<br/><br/> We have a new career Application from  a user. Please find details below:  
	                <table rules="all" style="border-color: #666;" cellpadding="10">
	                <tr><td><strong>Name:</strong> </td><td>${formData.firstName} ${formData.lastName}</td></tr>
                    <tr><td><strong>Email:</strong> </td><td>${formData.email} </td></tr>
                    <tr><td><strong>Mobile:</strong> </td><td> ${formData.mobile} </td></tr>
                    <tr><td><strong>Gender:</strong> </td><td>${formData.gender} </td></tr>
                    <tr><td><strong>City:</strong> </td><td> ${formData.city} </td></tr>
                    <tr><td><strong>Comments:</strong> </td><td> ${formData.comments} </td></tr>
                    <tr><td><strong>Privacy Accepted:</strong> </td><td>${formData.privacyAccepted}</td></tr>
                    </table></body></html>`;

    const mailData = {
      jcJson: {
        tos: "careers@snp.justconnectunite.com",
        //tos: "amankr.snp1@gmail.com",
        ccs: "",
        subject: `Career Application received from  ${formData.firstName}`,
        body: message,
        attachmentpath: formData.filePath,
      },
    };
    return axios.post(JCU_MAIL_SENDER_API_ENDPOINT, mailData);
    
  }

  sendMailtoUser(formData) {
    const message = `<html><body> Dear ${formData.firstName},<br/><br/>
                    Thanks for showing interest for this role. We have received your application. Our team will verify that and will get back to you with further details.
                    <br/><br/>Best Regards,<br/>JCU Recruitment Team<br/>
                    Please Like, Follow, Subscribe on Social Platform for latest updates</br>
                    <a href="https://www.youtube.com/channel/UCwQCwZvSeTDGtIE5rPOLaKg">Youtube</a>
                    <a href="https://www.facebook.com/Just-Connect-Unite-101868548789106">Facebook</a>
                    <a href="https://instagram.com/justconnectunite_snp?utm_medium=copy_link">Instagram</a>
                    </body></html>`;

    const mailData = {
      jcJson: {
        tos: formData.email,
        ccs: "",
        subject: `JCU - Careers Notification! `,
        body: message,
        attachmentpath: "",
      },
    };
    return axios.post(JCU_MAIL_SENDER_API_ENDPOINT, mailData);

  }

  saveCandidateEmployeeData(formData) {
    console.log("CSV Data: ", formData);
    return axios.post(
      BASE_JCU_SITE_SERVICE_URL + SAVE_CAREERS_EMPLOYEE_DATA_ENDPOINT,
      formData
    );
  }
}

export default new CareersService();
