import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DialogBox(props) {
  const {
    showDialog = false,
    onClose = () => {},
    title = "",
    dialogContent = "",
    dialogActions = "",
    children,
  } = props;

  const handleClose = (event) => {
    onClose(event);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}

      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            {renderDialogContent}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          /> */}
          {dialogContent}
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
