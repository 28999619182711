import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function History(props) {
  const renderContent = () => {
    if (props.content.length > 0) {
      return props.content.map((s) => {
        return <Typography key={s.toString()} paragraph>{s}</Typography>;
      });
    } else {
      return <p>Loading Data ....</p>;
    }
  };
  return (
    <div>
      <div id="history">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              History
            </Typography>
            <br />
            <br />
            {renderContent()}
            {/* <Typography paragraph>{props.content}</Typography> */}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
