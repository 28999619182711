import React from "react";
import { Carousel } from "react-responsive-carousel";
import VideoPlayer from "../components/VideoPlayer";

export default function VideoCarousel(props) {
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  // const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

  // const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

  // const customRenderThumb = (children) =>
  //     children.map((item) => {
  //         const videoId = getVideoId(item.props.url);
  //         return <img src={getVideoThumb(videoId)} />;
  //     });

  const renderCarousal = () => {
   // console.log("vu", props.videoUrls);
    if (props.videoUrls.length > 0) {
      return props.videoUrls.map((v) => {
        return (
          //<React.Fragment key={v.toString()}>
            <VideoPlayer
              loop={true}
              controls={true}
              width="100%"
              height="100%"
              isSelected={true}
              url={v.video}
              key={v.toString()}
            />
        //  </React.Fragment>
        );
      });
    }
  };
  return (
    <Carousel
      renderItem={customRenderItem}
      autoPlay
      infiniteLoop
      showThumbs={false}
      interval={10000}
    >
      {renderCarousal()}
    </Carousel>
  );
}
