import axios from "axios";
import {
  BASE_JCU_SITE_SERVICE_URL,
  JCU_MAIL_SENDER_API_ENDPOINT,
  // JCU_MAIL_SERVIC_URL,
  SAVE_REWARDS_DATA_ENDPOINT,
} from "../constants/Constants";

class RewardsService {
  sendInternalMail(userData) {
    const message = `<html><body> Hi Team,<br/><br/> We have a new user who has solved the puzzle and uploaded. Please find details below: . 
	                <table rules="all" style="border-color: #666;" cellpadding="10">
	                <tr><td><strong>Name:</strong> </td><td>${userData.firstName} ${userData.lastName}</td></tr>
                    <tr><td><strong>Email:</strong> </td><td>${userData.email} </td></tr>
                    <tr><td><strong>Mobile:</strong> </td><td> ${userData.mobile} </td></tr>
                    <tr><td><strong>City:</strong> </td><td>${userData.city} </td></tr>
                    <tr><td><strong>PIN:</strong> </td><td> ${userData.zipCode} </td></tr>
                    <tr><td><strong>Privacy Accepted:</strong> </td><td>${userData.privacyAccepted}</td></tr>
                    </table></body></html>`;

    const mailData = {
      jcJson: {
        tos: "rewards@snp.justconnectunite.com",
        //tos: "amankr.snp1@gmail.com",
        ccs: "",
        subject: `Puzzle uploaded by ${userData.firstName}`,
        body: message,
        attachmentpath:userData.filePath,
      },
    };
    return axios.post(JCU_MAIL_SENDER_API_ENDPOINT, mailData);
  }

  sendMailToUser(formData) {
    // return axios.post(JCU_MAIL_SERVIC_URL, {
    //   ...formData,
    //   mailType: "RewardsUserMail",
    // });

    const message = `<html><body> Dear ${formData.firstName},<br/><br/>
	                Thanks for participating in the contest. You are one more step closer to get attractive prizes. We have received your solved puzzle. Our team will verify that and you will be notified. We will also upload the winner list on our official site <a href="www.justconnectunite.com">www.justconnectunite.com</a>.
                  <br/>  
                  <br/><br/>Best Regards,<br/>JCU Rewards Team<br/>
                  Please Like, Follow, Subscribe on Social Platform for latest updates</br>
                  <a href="https://www.youtube.com/channel/UCwQCwZvSeTDGtIE5rPOLaKg">Youtube</a>
                  <a href="https://www.facebook.com/Just-Connect-Unite-101868548789106">Facebook</a>
                  <a href="https://instagram.com/justconnectunite_snp?utm_medium=copy_link">Instagram</a>
                  </body></html>`;

    const mailData = {
      jcJson: {
        tos: formData.email,
        ccs: "",
        subject: `JCU - Rewards Notification!!`,
        body: message,
        attachmentpath: "",
      },
    };
    return axios.post(JCU_MAIL_SENDER_API_ENDPOINT, mailData);
  }

  saveRewardsData(formData) {
    console.log("CSV Data: ", formData);
    return axios.post(
      BASE_JCU_SITE_SERVICE_URL + SAVE_REWARDS_DATA_ENDPOINT,
      formData
    );
  }
}

export default new RewardsService();
